import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BaseLayout from '../layout/BaseLayout';
// Encuestas
import SatisfaccionGeneral from '../views/Encuestas/SatisfaccionGeneral';
import SatisfaccionGeneralG from '../views/Encuestas/SatisfaccionGeneralG';
import SatisfaccionODE from '../views/Encuestas//SatisfaccionODE';
import SatisfaccionOperaciones from '../views/Encuestas/SatisfaccionOperaciones';
import SatisfaccionOperativa from '../views/Encuestas/SatisfaccionOperativa';
import SatisfaccionOperativaPruebas from '../views/Administracion/EncuestaSatisfaccionOperativa/Prueba';
import SatisfaccionComercial from '../views/Encuestas/SatisfaccionComercial';
// Administracion
import Login from '../views/Administracion/Login';
import ResetPassword from '../views/Administracion/ResetPassword';
import Perfil from '../views/Administracion/Perfil';
import Dashboard from '../views/Administracion/Dashboard';
import Usuarios from '../views/Administracion/Usuarios';
import UsuariosInactivos from '../views/Administracion/Usuarios/Inactivos';
import UsuariosCrear from '../views/Administracion/Usuarios/Crear';
import UsuariosActualizar from '../views/Administracion/Usuarios/Actualizar';
import Consultor from '../views/Administracion/Consultor';
import ReporteComercial from '../views/Administracion/ReporteComercial';
import ReporteConsultor from '../views/Administracion/ReporteConsultor';
import ReporteDetalladoConsultor from '../views/Administracion/ReporteDetalladoConsultor';
import ReporteEncuestaSatisfaccionComercial from '../views/Administracion/ReporteEncuestaSatisfaccionComercial';
import ReporteEncuestaSatisfaccionOperativa from '../views/Administracion/ReporteEncuestaSatisfaccionOperativa';
import ReporteSatisfaccionODE from '../views/Administracion/ReporteSatisfaccionODE';
import ReporteSatisfaccionOperaciones from '../views/Administracion/ReporteSatisfaccionOperaciones';
import EncuestaSatisfaccionOperativa from '../views/Administracion/EncuestaSatisfaccionOperativa';
import EncuestaSatisfaccionOperativaLista from '../views/Administracion/EncuestaSatisfaccionOperativa/Lista';
import EncuestaConsultorComercial from '../views/Administracion/EncuestaConsultorComercial';
import EncuestaConsultorComercialLista from '../views/Administracion/EncuestaConsultorComercial/Lista';
import Error from '../views/Error';
import ErrorPublica from '../views/ErrorPublica';

export default function Navigation() {
	return (
		<>
			<Router>
				<Routes>
					<Route path='/' element={<Login />} />
					<Route path='/reestablecer-credenciales' element={<ResetPassword />} />
					<Route path='/satisfaccion-general' element={<SatisfaccionGeneral />} />
					<Route path='/satisfaccion-general-g' element={<SatisfaccionGeneralG />} />
					<Route path='/satisfaccion-ode-entrenamiento' element={<SatisfaccionODE />} />
					<Route path='/satisfaccion-operaciones' element={<SatisfaccionOperaciones />} />
					<Route path='/encuesta-de-satisfaccion-operativa/:id' element={<SatisfaccionOperativa />} />
					<Route path='/pruebas/encuesta-de-satisfaccion-operativa' element={<SatisfaccionOperativaPruebas />} />
					<Route path='/encuesta-de-satisfaccion-comercial/:id' element={<SatisfaccionComercial />} />
					<Route path='*' element={<ErrorPublica />} />
					<Route element={<BaseLayout />}>
						<Route path='/admin/dashboard' element={<Dashboard />} />
						<Route path='/admin/configuracion' element={<Perfil />} />
						<Route path='/admin/usuarios' element={<Usuarios />} />
						<Route path='/admin/usuarios/inactivos' element={<UsuariosInactivos />} />
						<Route path='/admin/usuarios/crear' element={<UsuariosCrear />} />
						<Route path='/admin/usuarios/actualizar/:id' element={<UsuariosActualizar />} />
						<Route path='/admin/consultores' element={<Consultor />} />
						{/* <Route path='/admin/comercial/negocios-ganados' element={<ReporteComercial />} />
						<Route path='/admin/comercial/negocios-perdidos' element={<ReporteComercial />} /> */}
						<Route path='/admin/comercial/consultor' element={<ReporteConsultor />} />
						<Route path='/admin/comercial/consultor/:id' element={<ReporteDetalladoConsultor />} />
						{/* <Route path='/admin/operaciones/satisfaccion-ode' element={<ReporteSatisfaccionODE />} />
						<Route path='/admin/operaciones/encuesta-de-satisfaccion-operativa' element={<ReporteSatisfaccionOperaciones />} /> */}

						{/* abner */}
						<Route path='/admin/encuestas/encuesta-de-satisfaccion-operativa' element={<EncuestaSatisfaccionOperativa />} />
						<Route path='/admin/encuestas/encuesta-de-satisfaccion-operativa/lista' element={<EncuestaSatisfaccionOperativaLista />} />
						<Route path='/admin/comercial/reporte-satisfaccion-operativa' element={<ReporteEncuestaSatisfaccionOperativa />} />

						{/* juss */}
						<Route path='/admin/encuestas/encuesta-de-satisfaccion-comercial' element={<EncuestaConsultorComercial />} />
						<Route path='/admin/encuestas/encuesta-de-satisfaccion-comercial/lista' element={<EncuestaConsultorComercialLista />} />
						<Route path='/admin/comercial/reporte-satisfaccion-comercial' element={<ReporteEncuestaSatisfaccionComercial />} />

						<Route path='*' element={<Error />} />
					</Route>
				</Routes>
			</Router>
		</>
	);
}
