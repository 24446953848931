import React from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';

export default function Nomenglatura() {
	return (
		<>
			<Grid item xs={12} sm={12} md={12} lg={12} className='mb30'>
				<Typography color='text' colorBrightness='secondary'>
					P6 = ¿El asesor comercial fue cordial durante todo el proceso comercial?
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P7 = ¿El asesor comercial fue puntual en las citas acordadas?
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P8 = ¿El asesor comercial realizó la presentación de Spira y le permitió entender con claridad los servicios que prestamos, nuestra experiencia y casos de éxito?
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P9 = ¿El Asesor comercial demostró haber entendido las necesidades de su negocio /operación cuando presentó la propuesta?
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P10 = ¿El asesor comercial le entregó la propuesta comercial en el tiempo acordado?
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P11 = ¿Cuándo el asesor comercial presentó la oferta personalmente, le aclaró las dudas o inquietudes presentadas por su organización?
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P12 = ¿El asesor comercial evidenció los beneficios que obtendrá su organización en el aumento del desempeño y/o el cumplimiento de sus objetivos?
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P13 = ¿La propuesta comercial fue clara y respondió a sus necesidades?
				</Typography>
				<Typography color='text' colorBrightness='secondary'>
					P14 = ¿Después de la presentación de la propuesta, el asesor comercial mantuvo un permanente contacto e hizo seguimiento de esta?
				</Typography>
			</Grid>
		</>
	);
}
