import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Grid from '@mui/system/Unstable_Grid';
import { Button } from '@mui/material';
import SectionTitle from '../../../components/SeccionTitle';
import datos from './datos';
import { userActive } from '../../../assets/js/functions';
import satisfaccionOperaciones from '../../../assets/images/dashboard/satisfaccion-operaciones.png';
import './styles.scss';

export default function Dashboard() {
	const navigate = useNavigate();

	useEffect(() => {
		validarUsuario();
	}, []);

	const validarUsuario = async () => {
		const data = await userActive();
		if (data.cambiarContraseña) {
			Swal.fire({
				title: '¡Espera!',
				html: `Tienes una contraseña temporal por favor cambiala por una mas segura`,
				icon: 'warning',
				confirmButtonText: 'Cambiar',
				confirmButtonColor: '#c1212a',
			}).then((result) => {
				if (result.isConfirmed) {
					navigate('/admin/configuracion');
				}
			});
		}
	};

	const copiarClipBoard = (id) => {
		switch (id) {
			case 1:
				// Get the text field
				var copyText = document.getElementById('text1');
				copyText.select();
				copyText.setSelectionRange(0, 99999); // For mobile devices
				navigator.clipboard.writeText(copyText.value);
				Swal.fire({
					icon: 'success',
					title: 'Se copio el link',
					html: `${copyText.value}`,
					confirmButtonColor: '#e10613',
				});
				break;
			case 2:
				// Get the text field
				var copyText = document.getElementById('text2');
				copyText.select();
				copyText.setSelectionRange(0, 99999); // For mobile devices
				navigator.clipboard.writeText(copyText.value);
				Swal.fire({
					icon: 'success',
					title: 'Se copio el link',
					html: `${copyText.value}`,
					confirmButtonColor: '#e10613',
				});
				break;
			case 3:
				// Get the text field
				var copyText = document.getElementById('text3');
				copyText.select();
				copyText.setSelectionRange(0, 99999); // For mobile devices
				navigator.clipboard.writeText(copyText.value);
				Swal.fire({
					icon: 'success',
					title: 'Se copio el link',
					html: `${copyText.value}`,
					confirmButtonColor: '#e10613',
				});
				break;
			case 4:
				// Get the text field
				var copyText = document.getElementById('text4');
				copyText.select();
				copyText.setSelectionRange(0, 99999); // For mobile devices
				navigator.clipboard.writeText(copyText.value);
				Swal.fire({
					icon: 'success',
					title: 'Se copio el link',
					html: `${copyText.value}`,
					confirmButtonColor: '#e10613',
				});
				break;

			default:
				break;
		}
	};

	return (
		<>
			<div className='content-area'>
				<SectionTitle title='Dashboard' />
				<section id='dashboard'>
					<Grid container direction='row' spacing={2}>
						{/* {Object.values(datos).map((val, index) => (
							<Grid item xs={12} sm={6} md={4} lg={4}>
								<div className='center container-reports'>
									<div className='title-module'>{val.titulo}</div>
									<div className='full center'>
										<img src={val.img} alt='icon' className='full' />
									</div>
									<Grid container direction='row' spacing={2}>
										<Grid item xs={12} sm={6} md={6} lg={6}>
											<Button className='btn' style={{ color: '#fff' }} onClick={() => window.open(val.urlEncuesta, '_blank')}>
												Ir a encuesta
											</Button>
										</Grid>
										<Grid item xs={12} sm={6} md={6} lg={6}>
											<Button id={`btn${index + 1}`} className='btn' onClick={() => copiarClipBoard(index + 1)}>
												Copiar link
											</Button>
											<input id={`text${index + 1}`} value={val.urlCopy} />
										</Grid>
									</Grid>
								</div>
							</Grid>
						))} */}
						{sessionStorage.getItem('8b6d1c1e') === '0242ac120002' && (
							<Grid item xs={12} sm={6} md={4} lg={4}>
								<div className='center container-reports'>
									<div className='title-module'>Encuesta de Satisfacción Operativa</div>
									<div className='full center'>
										<img src={satisfaccionOperaciones} alt='icon' className='full' />
									</div>
									<Grid container direction='row' spacing={2}>
										<Grid item xs={12} sm={12} md={12} lg={12}>
											<Button className='btn' style={{ color: '#fff' }} onClick={() => navigate('/admin/encuestas/encuesta-de-satisfaccion-operativa')}>
												Comenzar encuesta
											</Button>
											<input id={`text5`} value='' />
										</Grid>
									</Grid>
								</div>
							</Grid>
						)}

						<Grid item xs={12} sm={6} md={4} lg={4}>
							<div className='center container-reports'>
								<div className='title-module'>Encuesta de Satisfaccion Comercial</div>
								<div className='full center'>
									<img src={satisfaccionOperaciones} alt='icon' className='full' />
								</div>
								<Grid container direction='row' spacing={2}>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<Button className='btn' style={{ color: '#fff' }} onClick={() => navigate('/admin/encuestas/encuesta-de-satisfaccion-comercial')}>
											Comenzar encuesta
										</Button>
										<input id={`text5`} value='' />
									</Grid>
								</Grid>
							</div>
						</Grid>
					</Grid>
				</section>
			</div>
		</>
	);
}
