import { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { validateEmail, updateData, searchDataByValue, getDate } from '../../../assets/js/functions';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Container } from '@mui/system';
import Grid from '@mui/system/Unstable_Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Logo from '../../../assets/images/Spira-Color.png';
import './styles.scss';

export default function Login() {
	const [showPassword, setShowPassword] = useState(false);
	const [campos, setCampos] = useState({});
	const navigate = useNavigate();
	const auth = getAuth();

	useEffect(() => {
		// removeElementsByClass('theme-toggle-btn');
	}, []);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const rol = (string) => {
		let compact = '';
		switch (string) {
			case 'Super Administrador':
				compact = '0242ac120002';
				break;
			case 'Administrador':
				compact = '76c66ae87579';
				break;
			case 'Consultor':
				compact = '051c0d485398';
				break;

			default:
				break;
		}
		return compact;
	};

	const validar = async (e) => {
		e.preventDefault();
		if (campos.correo) {
			if (!validateEmail(campos.correo)) {
				Swal.fire({
					title: '¡Oooopss!',
					text: 'Ingresa un correo valido',
					icon: 'warning',
					confirmButtonText: 'Cerrar',
					confirmButtonColor: '#c1212a',
				});
				return null;
			}
		} else {
			Swal.fire({
				title: '¡Oooopss!',
				text: 'El campo correo electrónico esta vacio',
				icon: 'warning',
				confirmButtonText: 'Cerrar',
				confirmButtonColor: '#c1212a',
			});
			return null;
		}

		const minusculas = campos.correo.toLowerCase();
		const result = await searchDataByValue('usuarios', 'correo', minusculas);
		signInWithEmailAndPassword(auth, campos.correo, campos.password)
			.then((userCredential) => {
				updateData('usuarios', result.id, { ultimaConexion: getDate() })
					.then(() => {
						sessionStorage.setItem('3c393f98', result.id);
						sessionStorage.setItem('8b6d1c1e', rol(result.rol));
						sessionStorage.setItem('531ac50224f238df5d6efdaf36507cf2', result.nombre);
						navigate('/admin/dashboard');
					})
					.catch((e) => console.log(e));
			})
			.catch((error) => {
				const errorCode = error.code;
				let msj = 'Contacta un Administrador';
				if (errorCode === 'auth/user-not-found') {
					msj = 'Correo no registrado';
				}
				if (errorCode === 'auth/wrong-password') {
					msj = 'Correo y/o Contraseña incorrecta';
				}
				Swal.fire({
					title: '¡Oooopss!',
					text: msj,
					icon: 'error',
					confirmButtonText: 'Cerrar',
					confirmButtonColor: '#c1212a',
				});
			});
	};

	return (
		<div id='login'>
			<Container maxWidth='md'>
				<div className='container-form-login'>
					<form onSubmit={validar}>
						<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
							<Grid item lg={7} md={7} sm={12} xs={12}>
								<div className='full center'>
									<img src={Logo} alt='logo' style={{ width: '300px' }} />
								</div>
							</Grid>
							<Grid item lg={7} md={7} sm={12} xs={12}>
								<div className='full center welcome'>¡Bienvenido!</div>
								&nbsp;
								<div className='full center mb-60 subtitle'>Ingresa tus datos para acceder a la plataforma</div>
							</Grid>
							<Grid item xs={12} sm={12} md={7} lg={7}>
								<FormControl fullWidth variant='filled' onChange={(e) => setCampos({ ...campos, correo: e.target.value })}>
									<InputLabel htmlFor='outlined-adornment-user'>Correo electrónico</InputLabel>
									<FilledInput
										id='outlined-adornment-user'
										type='email'
										autoComplete='false'
										endAdornment={
											<InputAdornment position='end'>
												<AlternateEmailIcon />
											</InputAdornment>
										}
										label='Usuario'
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={12} md={7} lg={7}>
								<FormControl fullWidth variant='filled' onChange={(e) => setCampos({ ...campos, password: e.target.value })}>
									<InputLabel htmlFor='outlined-adornment-password'>Contraseña</InputLabel>
									<FilledInput
										autoComplete='false'
										id='outlined-adornment-password'
										type={showPassword ? 'text' : 'password'}
										endAdornment={
											<IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										}
										label='Usuario'
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={12} md={7} lg={7}>
								<div className='full center space-footer-form'>
									<Button className='btn btn-yellow' type='submit' style={{ borderRadius: '20px' }}>
										Iniciar sesión
									</Button>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={7} lg={7}>
								<div className='center full reset-password' onClick={() => navigate('/reestablecer-credenciales')}>
									¿Olvidaste tu contraseña?
								</div>
							</Grid>
						</Grid>
					</form>
				</div>
			</Container>
		</div>
	);
}
