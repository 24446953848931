import { getCollections, getDate, cambiarTextos } from './functions';
import groupBy from 'lodash.groupby';
let xlsx = require('json-as-xlsx');

export async function SatisfaccionGeneralReporte() {
	let array = [];
	const results = await getCollections('satisfaccionGeneral');
	Object.values(results).map((val) => {
		array.push({
			...val,
			preguntaSeis: parseInt(val.preguntaSeis),
			preguntaSiete: parseInt(val.preguntaSiete),
			preguntaOcho: parseInt(val.preguntaOcho),
			preguntaNueve: parseInt(val.preguntaNueve),
			preguntaDiez: parseInt(val.preguntaDiez),
			preguntaOnce: parseInt(val.preguntaOnce),
			preguntaDoce: parseInt(val.preguntaDoce),
			preguntaTrece: parseInt(val.preguntaTrece),
			preguntaCatorce: parseInt(val.preguntaCatorce),
			preguntaQuince: parseInt(val.preguntaQuince),
		});
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Satisfacción General',
			columns: [
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de Consultor Comercial', value: 'preguntaTres' },
				{
					label: 'Por favor seleccione al consultor comercial. Si su respuesta es Otro por favor indique el nombre.',
					value: 'preguntaCuatro',
				},
				{
					label: 'Por favor indique el proyecto o propuesta a evaluar',
					value: 'preguntaCinco',
				},
				{
					label: '¿El asesor comercial fue cordial durante todo el proceso comercial?',
					value: 'preguntaSeis',
				},
				{
					label: '¿El asesor comercial fue puntual en las citas acordadas?',
					value: 'preguntaSiete',
				},
				{
					label: '¿El asesor comercial realizó la presentación de Spira y le permitió entender con claridad los servicios que prestamos, nuestra experiencia y casos de éxito?',
					value: 'preguntaOcho',
				},
				{
					label: '¿El Asesor comercial demostró haber entendido las necesidades de su negocio /operación cuando presentó la propuesta?',
					value: 'preguntaNueve',
				},
				{
					label: '¿El asesor comercial le entregó la propuesta comercial en el tiempo acordado?',
					value: 'preguntaDiez',
				},
				{
					label: '¿Cuándo el asesor comercial presentó la oferta personalmente, le aclaró las dudas o inquietudes presentadas por su organización?',
					value: 'preguntaOnce',
				},
				{
					label: '¿El asesor comercial evidenció los beneficios que obtendrá su organización en el aumento del desempeño y/o el cumplimiento de sus objetivos?',
					value: 'preguntaDoce',
				},
				{
					label: '¿La propuesta comercial fue clara y respondió a sus necesidades?',
					value: 'preguntaTrece',
				},
				{
					label: '¿Después de la presentación de la propuesta, el asesor comercial mantuvo un permanente contacto e hizo seguimiento de esta?',
					value: 'preguntaCatorce',
				},
				{
					label: 'En una escala de 0 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaQuince',
				},
				{ label: 'Fecha de registro', value: 'fechaRegistro' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Satisfacción-General-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function SatisfaccionGeneralGReporte() {
	let array = [];
	const results = await getCollections('satisfaccionGeneralG');
	Object.values(results).map((val) => {
		array.push({
			...val,
			preguntaSeis: parseInt(val.preguntaSeis),
			preguntaSiete: parseInt(val.preguntaSiete),
			preguntaOcho: parseInt(val.preguntaOcho),
			preguntaNueve: parseInt(val.preguntaNueve),
			preguntaDiez: parseInt(val.preguntaDiez),
			preguntaOnce: parseInt(val.preguntaOnce),
			preguntaDoce: parseInt(val.preguntaDoce),
			preguntaTrece: parseInt(val.preguntaTrece),
			preguntaCatorce: parseInt(val.preguntaCatorce),
			preguntaQuince: parseInt(val.preguntaQuince),
		});
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Satisfacción General Ganadas',
			columns: [
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de Consultor Comercial', value: 'preguntaTres' },
				{
					label: 'Por favor seleccione al consultor comercial. Si su respuesta es Otro por favor indique el nombre.',
					value: 'preguntaCuatro',
				},
				{
					label: 'Por favor indique el proyecto o propuesta a evaluar',
					value: 'preguntaCinco',
				},
				{
					label: '¿El asesor comercial fue cordial durante todo el proceso comercial?',
					value: 'preguntaSeis',
				},
				{
					label: '¿El asesor comercial fue puntual en las citas acordadas?',
					value: 'preguntaSiete',
				},
				{
					label: '¿El asesor comercial realizó la presentación de Spira y le permitió entender con claridad los servicios que prestamos, nuestra experiencia y casos de éxito?',
					value: 'preguntaOcho',
				},
				{
					label: '¿El Asesor comercial demostró haber entendido las necesidades de su negocio /operación cuando presentó la propuesta?',
					value: 'preguntaNueve',
				},
				{
					label: '¿El asesor comercial le entregó la propuesta comercial en el tiempo acordado?',
					value: 'preguntaDiez',
				},
				{
					label: '¿Cuándo el asesor comercial presentó la oferta personalmente, le aclaró las dudas o inquietudes presentadas por su organización?',
					value: 'preguntaOnce',
				},
				{
					label: '¿El asesor comercial evidenció los beneficios que obtendrá su organización en el aumento del desempeño y/o el cumplimiento de sus objetivos?',
					value: 'preguntaDoce',
				},
				{
					label: '¿La propuesta comercial fue clara y respondió a sus necesidades?',
					value: 'preguntaTrece',
				},
				{
					label: '¿Después de la presentación de la propuesta, el asesor comercial mantuvo un permanente contacto e hizo seguimiento de esta?',
					value: 'preguntaCatorce',
				},
				{
					label: 'En una escala de 0 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaQuince',
				},
				{ label: 'Fecha de registro', value: 'fechaRegistro' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Satisfacción-General-Ganadas-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function SatisfaccionOdeEntranamiento() {
	let array = [];
	const results = await getCollections('satisfaccionODE');
	Object.values(results).map((val) => {
		array.push({
			...val,
			preguntaCinco: parseInt(val.preguntaCinco),
			preguntaSeis: parseInt(val.preguntaSeis),
			preguntaSiete: parseInt(val.preguntaSiete),
			preguntaOcho: parseInt(val.preguntaOcho),
			preguntaNueve: parseInt(val.preguntaNueve),
			preguntaDiez: parseInt(val.preguntaDiez),
			preguntaOnce: parseInt(val.preguntaOnce),
			preguntaDoce: parseInt(val.preguntaDoce),
			preguntaTrece: parseInt(val.preguntaTrece),
			preguntaCatorce: parseInt(val.preguntaCatorce),
		});
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Satisfacción ODE Entrenamiento',
			columns: [
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de Consultor Comercial', value: 'preguntaTres' },
				{
					label: 'Por favor indique el nombre del proyecto a evaluar',
					value: 'preguntaCuatro',
				},
				{
					label: '¿El equipo SPIRA generó empatía durante todo el proceso de implementación del proyecto?',
					value: 'preguntaCinco',
				},
				{
					label: '¿El equipo SPIRA definió un plan o cronograma de trabajo, donde especificó las fechas de entrega (parcial o total) del proyecto y criterios de aceptación de este por parte del cliente?',
					value: 'preguntaSeis',
				},
				{
					label: '¿El equipo SPIRA generó confianza por la claridad y conocimiento de las necesidades y expectativas del proyecto?',
					value: 'preguntaSiete',
				},
				{
					label: '¿El equipo SPIRA presentó una secuencia de actividades detallada e ilustrativa del proyecto a desarrollar?',
					value: 'preguntaOcho',
				},
				{
					label: '¿El equipo SPIRA contaba con los recursos humanos, técnicos y metodológicos necesarios para la ejecución del proyecto?',
					value: 'preguntaNueve',
				},
				{
					label: '¿El equipo SPIRA demostró tener los conocimientos y experiencia requeridos para la ejecución del proyecto?',
					value: 'preguntaDiez',
				},
				{
					label: '¿El equipo SPIRA realizó un seguimiento permanente y periódico del proyecto, de modo que se atendieron las inquietudes y requerimientos solicitados?',
					value: 'preguntaOnce',
				},
				{
					label: '¿El equipo SPIRA hizo las entregas pactadas con calidad y de acuerdo con las expectativas planteadas al inicio del proyecto?',
					value: 'preguntaDoce',
				},
				{
					label: '¿La solución presentada cumplió con sus expectativas en términos de calidad e innovación?',
					value: 'preguntaTrece',
				},
				{
					label: 'En una escala de 0 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaCatorce',
				},
				{ label: 'Fecha de registro', value: 'fechaRegistro' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Satisfacción-ODE-Entrenamientos-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function SatisfaccionOperaciones() {
	let array = [];
	const results = await getCollections('satisfaccionOperaciones');
	Object.values(results).map((val) => {
		array.push({
			...val,
			preguntaCinco: parseInt(val.preguntaCinco),
			preguntaSeis: parseInt(val.preguntaSeis),
			preguntaSiete: parseInt(val.preguntaSiete),
			preguntaOcho: parseInt(val.preguntaOcho),
			preguntaNueve: parseInt(val.preguntaNueve),
			preguntaDiez: parseInt(val.preguntaDiez),
			preguntaOnce: parseInt(val.preguntaOnce),
			preguntaDoce: parseInt(val.preguntaDoce),
			preguntaTrece: parseInt(val.preguntaTrece),
			preguntaCatorce: parseInt(val.preguntaCatorce),
			preguntaQuince: parseInt(val.preguntaQuince),
			preguntaDieciseis: parseInt(val.preguntaDieciseis),
			preguntaDiecisiete: parseInt(val.preguntaDiecisiete),
			preguntaDieciocho: parseInt(val.preguntaDieciocho),
			preguntaDiecinueve: parseInt(val.preguntaDiecinueve),
			preguntaVeinte: parseInt(val.preguntaVeinte),
		});
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Satisfacción Operaciones',
			columns: [
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de Consultor Comercial', value: 'preguntaTres' },
				{
					label: 'Por favor indique el nombre del proyecto a evaluar',
					value: 'preguntaCuatro',
				},
				{
					label: '¿El equipo de trabajo asignado se ajusta ágilmente a los cambios de la operación?',
					value: 'preguntaCinco',
				},
				{
					label: '¿El equipo de trabajo asignado genera propuestas que agregan valor a la operación?',
					value: 'preguntaSeis',
				},
				{
					label: '¿El equipo de trabajo asignado es apreciado por el público objetivo de su organización?',
					value: 'preguntaSiete',
				},
				{
					label: '¿Para su organización es valiosa la gestión de SPIRA y su equipo de trabajo en el logro de sus objetivos?',
					value: 'preguntaOcho',
				},
				{
					label: '¿SPIRA ha cumplido con la oferta de valor pactada en los acuerdos de servicio/contrato?',
					value: 'preguntaNueve',
				},
				{
					label: '¿En la operación se siente la presencia de SPIRA como proveedor y como empresa?',
					value: 'preguntaDiez',
				},
				{
					label: '¿El equipo de trabajo asignado ha generado empatía en todo el proceso de implementación del proyecto?',
					value: 'preguntaOnce',
				},
				{
					label: '¿El equipo de trabajo asignado definió un plan o cronograma de trabajo, donde especificó las fechas de entrega(parcial o total) del proyecto y criterios de aceptación de este por parte del cliente?',
					value: 'preguntaDoce',
				},
				{
					label: '¿El equipo de trabajo asignado genera confianza por la claridad y conocimiento de las necesidades y expectativas del proyecto?',
					value: 'preguntaTrece',
				},
				{
					label: '¿El equipo de trabajo asignado presentó una secuencia de actividades detallada e ilustrativa del proyecto a desarrollar?',
					value: 'preguntaCatorce',
				},
				{
					label: '¿El equipo SPIRA cuenta con los recursos humanos, técnicos y metodológicos necesarios para la ejecución del proyecto?',
					value: 'preguntaQuince',
				},
				{
					label: '¿El equipo de trabajo asignado demuestra tener los conocimientos y experiencia requeridos para la ejecución del proyecto?',
					value: 'preguntaDieciseis',
				},
				{
					label: '¿El equipo de trabajo asignado realiza un seguimiento permanente y periódico del proyecto, de modo que se atiendan las inquietudes y requerimientos solicitados?',
					value: 'preguntaDiecisiete',
				},
				{
					label: '¿El equipo de trabajo asignado hace las entregas pactadas con calidad y de acuerdo con las expectativas planteadas al inicio del proyecto?',
					value: 'preguntaDieciocho',
				},
				{
					label: '¿La solución presentada cumple con sus expectativas en términos de calidad e innovación?',
					value: 'preguntaDiecinueve',
				},
				{
					label: 'En una escala de 1 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaVeinte',
				},
				{ label: 'Fecha de registro', value: 'fechaRegistro' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Satisfacción-Operaciones-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function reporteUsuarios() {
	const results = await getCollections('usuarios');
	const fecha = cambiarTextos(getDate());

	let data = [
		{
			sheet: 'USuarios',
			columns: [
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Correo electrónico', value: 'correo' },
				{ label: 'Rol', value: 'rol' },
				{ label: 'Teléfono', value: 'telefono' },
				{ label: 'País', value: 'pais' },
				{ label: 'Fecha de registro', value: 'fechaRegistro' },
			],
			content: results,
		},
	];

	let settings = {
		fileName: `Usuarios-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function reporteConsultores() {
	const results = await getCollections('consultores');
	const fecha = cambiarTextos(getDate());

	let data = [
		{
			sheet: 'Consultores',
			columns: [
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Cargo', value: 'cargo' },
				{ label: 'Correo electrónico', value: 'correo' },
				{ label: 'País', value: 'pais' },
				{ label: 'Fecha de registro', value: 'fechaRegistro' },
			],
			content: results,
		},
	];

	let settings = {
		fileName: `Consultores-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function reporteConsultoresExcel(id) {
	let ganadas = [];
	let perdidas = [];
	let array = [];

	const resultsGanadas = await getCollections('satisfaccionGeneralG');
	const resultsPerdidas = await getCollections('satisfaccionGeneral');
	Object.values(resultsGanadas).map((val) => {
		array.push({ ...val, perdidas: true });
	});
	Object.values(resultsPerdidas).map((val) => {
		array.push({ ...val, ganadas: true });
	});
	const orderData = groupBy(array, 'preguntaCuatro');

	Object.keys(orderData).map((key) => {
		if (key === id) {
			const values = orderData[key];
			if (values) {
				const valuess = Object.keys(values);
				Object.values(valuess).map((val) => {
					const data = orderData[key][val];
					if (data.ganadas) {
						perdidas.push({ ...data, nombre: id });
					}
					if (data.perdidas) {
						ganadas.push({ ...data, nombre: id });
					}
				});
			}
		}
	});

	const fecha = cambiarTextos(getDate());

	let data = [
		{
			sheet: 'Ganadas',
			columns: [
				{ label: 'Nombre', value: 'nombre' },
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de Consultor Comercial', value: 'preguntaTres' },
				{
					label: 'Por favor seleccione al consultor comercial. Si su respuesta es Otro por favor indique el nombre.',
					value: 'preguntaCuatro',
				},
				{
					label: 'Por favor indique el proyecto o propuesta a evaluar',
					value: 'preguntaCinco',
				},
				{
					label: '¿El asesor comercial fue cordial durante todo el proceso comercial?',
					value: 'preguntaSeis',
				},
				{
					label: '¿El asesor comercial fue puntual en las citas acordadas?',
					value: 'preguntaSiete',
				},
				{
					label: '¿El asesor comercial realizó la presentación de Spira y le permitió entender con claridad los servicios que prestamos, nuestra experiencia y casos de éxito?',
					value: 'preguntaOcho',
				},
				{
					label: '¿El Asesor comercial demostró haber entendido las necesidades de su negocio /operación cuando presentó la propuesta?',
					value: 'preguntaNueve',
				},
				{
					label: '¿El asesor comercial le entregó la propuesta comercial en el tiempo acordado?',
					value: 'preguntaDiez',
				},
				{
					label: '¿Cuándo el asesor comercial presentó la oferta personalmente, le aclaró las dudas o inquietudes presentadas por su organización?',
					value: 'preguntaOnce',
				},
				{
					label: '¿El asesor comercial evidenció los beneficios que obtendrá su organización en el aumento del desempeño y/o el cumplimiento de sus objetivos?',
					value: 'preguntaDoce',
				},
				{
					label: '¿La propuesta comercial fue clara y respondió a sus necesidades?',
					value: 'preguntaTrece',
				},
				{
					label: '¿Después de la presentación de la propuesta, el asesor comercial mantuvo un permanente contacto e hizo seguimiento de esta?',
					value: 'preguntaCatorce',
				},
				{
					label: 'En una escala de 0 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaQuince',
				},
			],
			content: ganadas,
		},
		{
			sheet: 'Perdidas',
			columns: [
				{ label: 'Nombre', value: 'nombre' },
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de Consultor Comercial', value: 'preguntaTres' },
				{
					label: 'Por favor seleccione al consultor comercial. Si su respuesta es Otro por favor indique el nombre.',
					value: 'preguntaCuatro',
				},
				{
					label: 'Por favor indique el proyecto o propuesta a evaluar',
					value: 'preguntaCinco',
				},
				{
					label: '¿El asesor comercial fue cordial durante todo el proceso comercial?',
					value: 'preguntaSeis',
				},
				{
					label: '¿El asesor comercial fue puntual en las citas acordadas?',
					value: 'preguntaSiete',
				},
				{
					label: '¿El asesor comercial realizó la presentación de Spira y le permitió entender con claridad los servicios que prestamos, nuestra experiencia y casos de éxito?',
					value: 'preguntaOcho',
				},
				{
					label: '¿El Asesor comercial demostró haber entendido las necesidades de su negocio /operación cuando presentó la propuesta?',
					value: 'preguntaNueve',
				},
				{
					label: '¿El asesor comercial le entregó la propuesta comercial en el tiempo acordado?',
					value: 'preguntaDiez',
				},
				{
					label: '¿Cuándo el asesor comercial presentó la oferta personalmente, le aclaró las dudas o inquietudes presentadas por su organización?',
					value: 'preguntaOnce',
				},
				{
					label: '¿El asesor comercial evidenció los beneficios que obtendrá su organización en el aumento del desempeño y/o el cumplimiento de sus objetivos?',
					value: 'preguntaDoce',
				},
				{
					label: '¿La propuesta comercial fue clara y respondió a sus necesidades?',
					value: 'preguntaTrece',
				},
				{
					label: '¿Después de la presentación de la propuesta, el asesor comercial mantuvo un permanente contacto e hizo seguimiento de esta?',
					value: 'preguntaCatorce',
				},
				{
					label: 'En una escala de 0 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaQuince',
				},
			],
			content: perdidas,
		},
	];

	let settings = {
		fileName: `${id}-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function SatisfaccionOperativa() {
	let array = [];
	let array11 = [];
	let array14 = [];
	const results = await getCollections('pruebas');

	Object.values(results).map((val) => {
		if (val.status === 'Terminado') {
			Object.values(val.respuestas).map((valRes) => {
				// pregunta11
				if (valRes.preguntaOnce['opcion1'] !== '') {
					array11.push(valRes.preguntaOnce.opcion1);
				}
				if (valRes.preguntaOnce['opcion2'] !== '') {
					array11.push(valRes.preguntaOnce.opcion2);
				}
				if (valRes.preguntaOnce['opcion3'] !== '') {
					array11.push(valRes.preguntaOnce.opcion3);
				}
				if (valRes.preguntaOnce['opcion4'] !== '') {
					array11.push(valRes.preguntaOnce.opcion4);
				}
				if (valRes.preguntaOnce['opcion5'] !== '') {
					array11.push(valRes.preguntaOnce.opcion5);
				}
				if (valRes.preguntaOnce['opcion6'] !== '') {
					array11.push(valRes.preguntaOnce.opcion6);
				}
				if (valRes.preguntaOnce['opcion7'] !== '') {
					array11.push(valRes.preguntaOnce.opcion7);
				}
				if (valRes.preguntaOnce['opcion8'] !== '') {
					array11.push(valRes.preguntaOnce.otro);
				}
				// pregunta12
				if (valRes.preguntaCatorce['opcion1'] !== '') {
					array14.push(valRes.preguntaCatorce.opcion1);
				}
				if (valRes.preguntaCatorce['opcion2'] !== '') {
					array14.push(valRes.preguntaCatorce.opcion2);
				}
				if (valRes.preguntaCatorce['opcion3'] !== '') {
					array14.push(valRes.preguntaCatorce.opcion3);
				}
				if (valRes.preguntaCatorce['opcion4'] !== '') {
					array14.push(valRes.preguntaCatorce.opcion4);
				}
				if (valRes.preguntaCatorce['opcion5'] !== '') {
					array14.push(valRes.preguntaCatorce.opcion5);
				}
				if (valRes.preguntaCatorce['opcion6'] !== '') {
					array14.push(valRes.preguntaCatorce.opcion6);
				}
				if (valRes.preguntaCatorce['opcion7'] !== '') {
					array14.push(valRes.preguntaCatorce.opcion7);
				}
				if (valRes.preguntaCatorce['opcion8'] !== '') {
					array14.push(valRes.preguntaCatorce.otro);
				}

				array.push({
					preguntaUno: val.preguntaUno,
					preguntaDos: val.preguntaDos,
					preguntaTres: val.preguntaTres,
					preguntaCuatro: val.preguntaCuatro,
					preguntaCinco: parseInt(valRes.preguntaSeis),
					preguntaSeis: parseInt(valRes.preguntaSeis),
					preguntaSiete: parseInt(valRes.preguntaSiete),
					preguntaOcho: parseInt(valRes.preguntaOcho),
					preguntaNueve: parseInt(valRes.preguntaNueve),
					preguntaDiez: parseInt(valRes.preguntaDiez),
					preguntaOnce: array11.toString(),
					preguntaDoce: parseInt(valRes.preguntaDoce),
					preguntaTrece: parseInt(valRes.preguntaTrece),
					preguntaCatorce: array14.toString(),
					preguntaQuince: parseInt(valRes.preguntaQuince),
					preguntaDieciseis: parseInt(valRes.preguntaDieciseis),
					preguntaDiecisiete: valRes.preguntaDiecisiete,
					fechaRegistroConsultor: val.fechaRegistroConsultor,
					fechaRegistroCliente: valRes.fechaRegistroCliente,
				});
			});
		}
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Satisfacción operativa',
			columns: [
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de consultor comercial', value: 'preguntaTres' },
				{
					label: 'Por favor indique el nombre del proyecto a evaluar',
					value: 'preguntaCuatro',
				},
				{
					label: 'El equipo Spira cumplió con la oferta presentada en la propuesta comercial',
					value: 'preguntaCinco',
				},
				{
					label: '¿El equipo Spira mostró experiencia y dominio técnico requerido para cumplir la oferta de valor?',
					value: 'preguntaSeis',
				},
				{
					label: 'Las propuestas presentadas responden a la estrategia, retos y necesidades de su área /organización',
					value: 'preguntaSiete',
				},
				{
					label: 'Las propuestas presentadas responden a las necesidades y preferencias del público objetivo',
					value: 'preguntaOcho',
				},
				{
					label: 'El equipo Spira mantuvo comunicación y acompañamiento durante todo el proyecto',
					value: 'preguntaNueve',
				},
				{
					label: 'El equipo Spira mostró flexibilidad, adaptación y brindó alternativas ante el cambio y/o nuevos requerimientos',
					value: 'preguntaDiez',
				},
				{
					label: 'Qué elementos reconoce en el SERVICIO brindado por el equipo SPIRA? Seleccione 1 o varios',
					value: 'preguntaOnce',
				},
				{
					label: 'Fue clara la secuencia de actividades e hitos para desarrollar el proyecto',
					value: 'preguntaDoce',
				},
				{
					label: 'Se comunicaron los avances y riesgos del proyecto con oportunidad',
					value: 'preguntaTrece',
				},
				{
					label: '¿Cómo describe las ejecuciones/entregables desarrollados por el equipo Spira? Seleccione 1 o varios',
					value: 'preguntaCatorce',
				},
				{
					label: '¿Qué tan satisfecho está con el trabajo del equipo SPIRA y el resultado de su proyecto?',
					value: 'preguntaQuince',
				},
				{
					label: 'En una escala de 1 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaDieciseis',
				},
				{
					label: '¿Desea expresar algún RECONOCIMIENTO, SUGERENCIA Y/O RETROALIMENTACIÓN? Por favor, escríbala a continuación.',
					value: 'preguntaDiecisiete',
				},

				{ label: 'Fecha de registro', value: 'fechaRegistroConsultor' },
				{ label: 'Fecha de culminación', value: 'fechaRegistroCliente' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Satisfacción-operativa-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function SatisfaccionComercial() {
	let array = [];
	const results = await getCollections('encuestaDeSatisfaccionComercial');
	Object.values(results).map((val) => {
		if (val.status === 'Terminado') {
			Object.values(val.respuestas).map((valRes) => {
				array.push({
					creador: val.consultor,
					preguntaUno: val.preguntaUno,
					preguntaDos: val.preguntaDos,
					preguntaTres: val.preguntaTres,
					preguntaCuatro: val.preguntaCuatro,
					preguntaCinco: parseInt(valRes.preguntaCinco),
					preguntaSeis: parseInt(valRes.preguntaSeis),
					preguntaSiete: parseInt(valRes.preguntaSiete),
					preguntaOcho: parseInt(valRes.preguntaOcho),
					preguntaNueve: parseInt(valRes.preguntaNueve),
					preguntaDiez: parseInt(valRes.preguntaDiez),
					preguntaOnce: parseInt(valRes.preguntaOnce),
					preguntaDoce: parseInt(valRes.preguntaDoce),
					preguntaTrece: parseInt(valRes.preguntaTrece),
					preguntaCatorce: parseInt(valRes.preguntaCatorce),
					preguntaQuince: parseInt(valRes.preguntaQuince),
					comentarios: valRes.comentarios,
					fechaRegistroConsultor: val.fechaRegistroConsultor,
					fechaRegistroCliente: val.fechaRegistroCliente,
				});
			});
		}
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Satisfacción comercial',
			columns: [
				{
					label: 'Consultor',
					value: 'consultore',
				},
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de consultor comercial', value: 'preguntaTres' },
				{
					label: 'Por favor indique el nombre del proyecto a evaluar',
					value: 'preguntaCuatro',
				},
				{
					label: '¿El Consultor Comercial cumplió en calidad con los compromisos adquiridos durante todo el proceso? (propuestas, citas, entregas, etc.)',
					value: 'preguntaCinco',
				},
				{
					label: '¿El Consultor Comercial cumplió en tiempo con los compromisos adquiridos durante todo el proceso? (propuestas, citas, entregas, etc.)',
					value: 'preguntaSeis',
				},
				{
					label: '¿El Consultor Comercial se tomo el tiempo de entender las necesidades de su negocio /operación cuando presentó la propuesta comercial?',
					value: 'preguntaSiete',
				},
				{
					label: '¿El Consultor Comercial evidenció los beneficios que obtendrá su organización en el aumento del desempeño y/o el cumplimiento de sus objetivos, a través de la propuesta comercial presentada?',
					value: 'preguntaOcho',
				},
				{
					label: '¿El Consultor Comercial presentó una propuesta que respondía a las necesidades planteadas?',
					value: 'preguntaNueve',
				},
				{
					label: '¿El Consultor Comercial mantuvo un permanente contacto e hizo seguimiento de la propuesta comercial presentada?',
					value: 'preguntaDiez',
				},
				{
					label: '¿El Consultor Comercial transmitió una imagen profesional correcta y agradable durante el proceso?',
					value: 'preguntaOnce',
				},
				{
					label: '¿El Consultor Comercial se comunicó de manera efectiva en el proceso?',
					value: 'preguntaDoce',
				},
				{
					label: '¿El Consultor Comercial creo un espacio agradable que hizo fácil la interacción?',
					value: 'preguntaTrece',
				},
				{
					label: '¿Le gustaría seguir siendo atendido por el Consultor Comercial asignado?',
					value: 'preguntaCatorce',
				},
				{
					label: 'En una escala de 0 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaQuince',
				},
				{
					label: 'Comentarios:',
					value: 'comentarios',
				},

				{ label: 'Fecha de registro', value: 'fechaRegistroConsultor' },
				{ label: 'Fecha de culminación', value: 'fechaRegistroCliente' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Satisfacción-comercfial-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}
