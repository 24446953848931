import React, { useState, useEffect, useCallback } from 'react';
import uniqid from 'uniqid';
import { useParams } from 'react-router-dom';
import { Container, Grid, InputLabel, FormControl, RadioGroup, FormControlLabel, Radio, Button, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import { getCollectionsComplete, getDate, top, updateData } from '../../../assets/js/functions';
import Logo from '../../../assets/images/login/logo.png';

export default function SatisfaccionConsultorComercial() {
	const [datos, setDatos] = useState({});
	const [seccionVisible, setSeccionVisible] = useState(1);
	const [contestado, setContestado] = useState(false);
	const params = useParams();

	useEffect(() => {
		top();
		validar();
	}, []);

	const validar = async () => {
		const data = await getCollectionsComplete(`encuestaDeSatisfaccionComercial/${params.id}`);
		const termino = localStorage.getItem('terminadoComercial') || 0;
		if (termino === '1') {
			setContestado(true);
		}

		if (data.status === 'Sin abrir') {
			updateData('encuestaDeSatisfaccionComercial', params.id, { status: 'Abierto' });
		}
	};

	const registrar = useCallback(() => {
		const idUniq = uniqid();
		const json = {
			...datos,
			fechaRegistroCliente: getDate(),
			status: 'Terminado',
		};
		if (Object.keys(datos).length < 11) {
			Swal.fire({
				icon: 'warning',
				title: 'Cuidado',
				text: 'Tienes preguntas sin contestar',
				confirmButtonColor: '#e10613',
			});
		} else {
			updateData(`encuestaDeSatisfaccionComercial`, params.id, { status: 'Terminado' });
			localStorage.setItem('terminadoComercial', 1);
			updateData(`encuestaDeSatisfaccionComercial/${params.id}/respuestas`, idUniq, json).then(() => {
				Swal.fire({
					icon: 'success',
					title: 'Bien Hecho',
					text: 'Datos guardados exitosamente...',
					confirmButtonColor: '#e10613',
				}).then((result) => {
					if (result.isConfirmed) {
						setContestado(true);
					}
				});
			});
		}
	}, [datos, params.id]);

	const cambiarSeccion = () => {
		top();
		setSeccionVisible(seccionVisible + 1);
	};

	let backgroundStyles = '';
	backgroundStyles += `#root::after {background-color: #fff }`;

	return (
		<>
			<style>{backgroundStyles}</style>
			<div className='header-free'>
				<img src={Logo} alt='logo' />
			</div>
			{contestado ? (
				<h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>Gracias por contestar la encuesta</h2>
			) : (
				<Container maxWidth='lg' style={{ marginBottom: '40px' }}>
					<Grid container justifyContent='center' alignItems='center' spacing={4}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div className='titulo-encuesta'>Satisfacción de satisfacción comercial</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<>
								<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial' }}>
									Por favor responda las siguientes preguntas según su apreciación de nuestro servicio, siendo 1 la calificación más baja.
								</InputLabel>
							</>
						</Grid>
						{/* seccionVisible 1 */}
						{seccionVisible === 1 && (
							<>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											1.¿El Consultor Comercial cumplió en calidad con los compromisos adquiridos durante todo el proceso? (propuestas, citas, entregas, etc.)
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaCinco: e.target.value })} value={datos.preguntaCinco}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											2.¿El Consultor Comercial cumplió en tiempo con los compromisos adquiridos durante todo el proceso? (propuestas, citas, entregas, etc.)
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaSeis: e.target.value })} value={datos.preguntaSeis}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											3.¿El Consultor Comercial se tomo el tiempo de entender las necesidades de su negocio /operación cuando presentó la propuesta comercial?
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaSiete: e.target.value })} value={datos.preguntaSiete}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											4.¿El Consultor Comercial evidenció los beneficios que obtendrá su organización en el aumento del desempeño y/o el cumplimiento de sus objetivos, a través de la propuesta comercial presentada?
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaOcho: e.target.value })} value={datos.preguntaOcho}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											5.¿El Consultor Comercial presentó una propuesta que respondía a las necesidades planteadas?
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaNueve: e.target.value })} value={datos.preguntaNueve}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
							</>
						)}

						{/* seccionVisible 2 */}
						{seccionVisible === 2 && (
							<>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											6.¿El Consultor Comercial mantuvo un permanente contacto e hizo seguimiento de la propuesta comercial presentada?
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaDiez: e.target.value })} value={datos.preguntaDiez}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											7.¿El Consultor Comercial transmitió una imagen profesional correcta y agradable durante el proceso?
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaOnce: e.target.value })} value={datos.preguntaOnce}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											8.¿El Consultor Comercial se comunicó de manera efectiva en el proceso?
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaDoce: e.target.value })} value={datos.preguntaDoce}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											9.¿El Consultor Comercial creo un espacio agradable que hizo fácil la interacción?
										</InputLabel>
										<FormControl>
											<RadioGroup
												row
												aria-labelledby='demo-row-radio-buttons-group-label'
												name='row-radio-buttons-group'
												onChange={(e) =>
													setDatos({
														...datos,
														preguntaTrece: e.target.value,
													})
												}
												value={datos.preguntaTrece}
											>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											10.¿Le gustaría seguir siendo atendido por el Consultor Comercial asignado?
										</InputLabel>
										<FormControl>
											<RadioGroup
												row
												aria-labelledby='demo-row-radio-buttons-group-label'
												name='row-radio-buttons-group'
												onChange={(e) =>
													setDatos({
														...datos,
														preguntaCatorce: e.target.value,
													})
												}
												value={datos.preguntaCatorce}
											>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											11.En una escala de 0 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?
										</InputLabel>
										<FormControl>
											<RadioGroup
												row
												aria-labelledby='demo-row-radio-buttons-group-label'
												name='row-radio-buttons-group'
												onChange={(e) =>
													setDatos({
														...datos,
														preguntaQuince: e.target.value,
													})
												}
												value={datos.preguntaQuince}
											>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='6'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='6'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='7'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='7'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='8'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='8'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='9'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='9'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='10'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='10'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											12. Comentarios:
										</InputLabel>
										<TextField id='outlined-multiline-static' multiline rows={4} fullWidth variant='outlined' value={datos.comentarios} onChange={(e) => setDatos({ ...datos, comentarios: e.target.value })} />
									</>
								</Grid>
							</>
						)}

						{/* Botones */}
						<Grid item xs={12} sm={12} md={12} lg={12}>
							{seccionVisible > 1 && (
								<Button className='blanco' variant='contained' style={{ background: '#e10613' }} onClick={() => setSeccionVisible(seccionVisible - 1)}>
									Regresar
								</Button>
							)}
							&nbsp;
							<Button className='blanco' variant='contained' style={{ background: '#e10613' }} onClick={() => (seccionVisible !== 2 ? cambiarSeccion() : registrar())}>
								{seccionVisible !== 2 ? 'Siguiente' : 'Enviar'}
							</Button>
						</Grid>
					</Grid>
				</Container>
			)}
		</>
	);
}
