import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import orderBy from 'lodash.orderby';
import Grid from '@mui/system/Unstable_Grid';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import SectionTitle from '../../../components/SeccionTitle';
import { getCollections } from '../../../assets/js/functions';
import { reporteConsultores } from '../../../assets/js/reportes';
import './styles.scss';

export default function Usuarios() {
	const [rowsTable, setRowsTable] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		getConsultores();
	}, []);

	const getConsultores = async () => {
		let array = [];
		const data = await getCollections('usuarios');
		Object.values(data).map((val) => {
			if (val.listaConsultores) {
				array.push(val);
			}
		});
		if (Object.keys(array).length > 0) {
			const ordernados = orderBy(array, ['nombre'], ['asc']);
			setRowsTable(ordernados);
		}
	};

	const busquedaFunction = (busqueda) => {
		setRowsTable(busqueda);
	};

	const columns = [
		{ field: 'nombre', headerName: 'NOMBRE', flex: 2 },
		{ field: 'correo', headerName: 'CORREO ELECTRÓNICO', flex: 2 },
		{ field: 'pais', headerName: 'PaÍs', flex: 2 },
		{
			field: 'action',
			headerName: 'OPCIONES',
			width: 200,
			sortable: false,
			renderCell: (params) => {
				return (
					<>
						<div className='cursor' style={{ marginRight: '15px' }} onClick={() => navigate(`/administracion/usuarios/actualizar/${params.id}`)}>
							<EditIcon />
						</div>
					</>
				);
			},
		},
	];

	return (
		<>
			<div className='content-area'>
				<SectionTitle title='Consultores' />
				<section id='consultores'>
					<Grid container direction='row' alignItems='stretch' spacing={2}>
						<Grid item lg={10} md={10} sm={10} xs={12}>
							{/* <InputSearch busquedaProps={busquedaFunction} collection='usuarios' /> */}
						</Grid>
						<Grid item lg={2} md={2} sm={2} xs={12}>
							<div className='btn' onClick={reporteConsultores}>
								Descargar
							</div>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<div style={{ height: `${rowsTable.length > 0 ? 'auto' : '600px'}`, width: '100%' }}>
								<DataGrid
									rows={rowsTable}
									columns={columns}
									pageSize={10}
									initialState={{
										pagination: { paginationModel: { pageSize: 10 } },
									}}
									pageSizeOptions={[10, 20, 50, 100]}
									rowsPerPageOptions={[10]}
									localeText={esES.components.MuiDataGrid.defaultProps.localeText}
								/>
							</div>
						</Grid>
					</Grid>
				</section>
			</div>
		</>
	);
}
