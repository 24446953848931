import { useState, useEffect } from 'react';
import { db } from '../../config/firebase';
import { ref, onChildChanged } from 'firebase/database';
import { getCollectionsComplete } from '../../assets/js/functions';
import './styles.scss';

export default function Buscar({ busquedaProps, collection, ignore = [] }) {
	const [buscar, setBuscar] = useState('');
	const [info, setInfo] = useState([]);
	let filtered = [];
	const excludeColumns = [''];

	useEffect(() => {
		getData();
		const dbRef = ref(db, `${collection}/`);
		onChildChanged(dbRef, (data) => {
			getData();
		});
	}, []);

	const getData = async () => {
		let array = [];
		const infoCollection = await getCollectionsComplete(`${collection}/`);
		Object.keys(infoCollection).map((key) => {
			const data = infoCollection[key];
			if (ignore.indexOf(key) === -1) {
				array.push(data);
			}
		});
		setInfo(array);
	};

	async function buscarChange(e) {
		const lowercasedValue = e.target.value.toLowerCase();
		setBuscar(lowercasedValue);

		filtered = info.filter((item) => {
			return Object.keys(item).some((key) => (excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)));
		});
		if (Object.keys(filtered).length < 1 || buscar.length < 1) {
			busquedaProps([]);
		} else {
			busquedaProps(filtered);
		}
	}

	return (
		<>
			<div className='input_icons'>
				<i className={`fa fa-search icon`} />
				<input type='text' onBlur={Object.keys(filtered).length < 1 ? () => getData() : () => busquedaProps(filtered)} className='input_search' placeholder='Escribe cualquier valor de la tabla para filtra...' value={buscar} onChange={buscarChange} />
			</div>
		</>
	);
}
