import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, MenuItem, Button, Paper, Switch, FormControlLabel } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import Swal from 'sweetalert2';
import SectionTitle from '../../../../components/SeccionTitle';
import { searchDataByValue, updateData } from '../../../../assets/js/functions';
import './styles.scss';

export default function ActualizarUsurios() {
	const params = useParams();
	const [campos, setCampos] = useState({});
	const [rol, setRol] = useState(null);
	const [pais, setPais] = useState(null);

	useEffect(() => {
		getInfo();
	}, []);

	const getInfo = async () => {
		const data = await searchDataByValue('usuarios', 'id', params.id);
		setRol(data.rol);
		setPais(data.pais);
		setCampos(data);
		console.log(data);
	};

	const validar = async (e) => {
		e.preventDefault();
		const minusculas = campos.correo.toLowerCase();
		let json = {};
		json = {
			...campos,
			rol,
			pais,
			minusculas,
		};
		updateData('usuarios', campos.id, json)
			.then(() => {
				Swal.fire({
					title: '¡Bien hecho!',
					text: `Actualización exitosa`,
					icon: 'success',
					confirmButtonText: 'Cerrar',
					confirmButtonColor: '#c1212a',
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.reload();
					}
				});
			})
			.catch((e) => console.log(e));
	};

	return (
		<>
			<div className='content-area'>
				<SectionTitle title='Actualizar usuarios' urlPadre={['Usuarios', '/admin/usuarios']} />
				<section id='usuariosActualizar'>
					<form onSubmit={validar}>
						<Grid container direction='row' spacing={2}>
							<Grid item lg={4} md={4} sm={4} xs={12}>
								<TextField InputLabelProps={{ shrink: true }} required fullWidth id='nombre' value={campos.nombre} label='Nombre' variant='outlined' onChange={(e) => setCampos({ ...campos, nombre: e.target.value })} />
							</Grid>
							<Grid item lg={4} md={4} sm={4} xs={12}>
								<TextField InputLabelProps={{ shrink: true }} type='email' required fullWidth id='correo-electronico' value={campos.correo} label='Correo Electrónico' variant='outlined' onChange={(e) => setCampos({ ...campos, correo: e.target.value })} />
							</Grid>
							<Grid item lg={4} md={4} sm={4} xs={12}>
								<TextField InputLabelProps={{ shrink: true }} required fullWidth select id='pais' value={pais} label='País' variant='outlined' onChange={(e) => setPais(e.target.value)}>
									<MenuItem value='Brasil'>Brasil</MenuItem>
									<MenuItem value='Colombia'>Colombia</MenuItem>
									<MenuItem value='Ecuador'>Ecuador</MenuItem>
									<MenuItem value='España'>España</MenuItem>
									<MenuItem value='México'>México</MenuItem>
									<MenuItem value='Perú'>Perú</MenuItem>
								</TextField>
							</Grid>
							<Grid item lg={6} md={6} sm={6} xs={12}>
								<TextField InputLabelProps={{ shrink: true }} required fullWidth select id='rol' value={rol} label='Rol' variant='outlined' onChange={(e) => setRol(e.target.value)}>
									<MenuItem value='Super Administrador'>Super Administrador</MenuItem>
									<MenuItem value='Administrador'>Administrador</MenuItem>
									<MenuItem value='Consultor'>Consultor</MenuItem>
								</TextField>
							</Grid>
							<Grid item lg={6} md={6} sm={6} xs={12}>
								<TextField
									InputLabelProps={{ shrink: true }}
									type='tel'
									inputProps={{ pattern: '[0-9]{10}', maxLength: 10 }}
									fullWidth
									id='telefono'
									value={campos.telefono}
									label='Teléfono (opcional)'
									placeholder='0000000000'
									variant='outlined'
									onChange={(e) => setCampos({ ...campos, telefono: e.target.value })}
								/>
							</Grid>
							<Grid item lg={10} md={10} sm={10} xs={12}>
								<FormControlLabel
									value={campos.listaConsultores}
									onChange={(e) => setCampos({ ...campos, listaConsultores: !campos.listaConsultores })}
									control={<Switch checked={campos.listaConsultores} color='primary' />}
									label='Mostrar en lista de consultores'
									labelPlacement='start'
								/>
							</Grid>
							<Grid item lg={10} md={10} sm={10} xs={12}>
								<FormControlLabel value={campos.activo} onChange={(e) => setCampos({ ...campos, activo: !campos.activo })} control={<Switch checked={campos.activo} color='primary' />} label='Activo' labelPlacement='start' />
							</Grid>
						</Grid>
						<Grid container direction='row' spacing={2}>
							<Grid item lg={2} md={2} sm={2} xs={12}>
								<div className='full center'>
									<Button type='submit' variant='info' className='btn'>
										Actualizar
									</Button>
								</div>
							</Grid>
						</Grid>
					</form>
				</section>
			</div>
		</>
	);
}
