import React, { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { TextField, MenuItem, Button, Paper, Switch, FormControlLabel } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import uniqid from 'uniqid';
import Swal from 'sweetalert2';
import SectionTitle from '../../../../components/SeccionTitle';
import { getDate, setData, searchDataByValue } from '../../../../assets/js/functions';
import './styles.scss';

export default function CrearUsurios() {
	const [campos, setCampos] = useState({});
	const [password, setPassword] = useState(null);
	const auth = getAuth();

	useEffect(() => {
		setPassword('Sp1r@*');
	}, []);

	const validar = async (e) => {
		e.preventDefault();
		const id = uniqid();
		let json = {};
		json = {
			...campos,
			id,
			cambiarContraseña: true,
			activo: true,
			fechaRegistro: getDate(),
		};
		const minusculas = campos.correo.toLowerCase();
		const existCorreo = await searchDataByValue('usuarios', 'correo', minusculas);
		if (Object.keys(existCorreo).length > 0) {
			Swal.fire({
				title: 'Precaución!',
				text: 'Este correo electrónico ya se encuentra registrado',
				icon: 'warning',
				confirmButtonText: 'Cerrar',
				confirmButtonColor: '#c1212a',
			});
		} else {
			createUserWithEmailAndPassword(auth, minusculas, password)
				.then(async (userCredential) => {
					setData('usuarios', id, json).then(() => {
						Swal.fire({
							title: '¡Registro exitoso!',
							html: `Contraseña generada: <b>${password}</b>`,
							icon: 'success',
							confirmButtonText: 'Cerrar',
							confirmButtonColor: '#c1212a',
						}).then((result) => {
							if (result.isConfirmed) {
								window.location.reload();
							}
						});
					});
				})
				.catch((e) => {
					const errorCode = e.code;
					const errorMessage = e.message;
					let json = {
						...campos,
						log: 'register',
						errorMessage,
						errorCode,
					};
					setData('log', uniqid(), json).then(() => {
						Swal.fire({
							title: 'Oooops!',
							text: 'Contacta un administrador',
							icon: 'error',
							confirmButtonText: 'Cerrar',
							confirmButtonColor: '#c1212a',
						});
					});
				});
		}
	};

	return (
		<>
			<div className='content-area'>
				<SectionTitle title='Registro de usuarios' urlPadre={['Usuarios', '/admin/usuarios']} />
				<section id='usuariosCrear'>
					<form onSubmit={validar}>
						<Grid container direction='row' spacing={2}>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<TextField required fullWidth id='nombre' label='Nombre' variant='outlined' onChange={(e) => setCampos({ ...campos, nombre: e.target.value })} />
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<TextField required fullWidth id='cargo' label='Cargo' variant='outlined' onChange={(e) => setCampos({ ...campos, cargo: e.target.value })} />
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<TextField type='email' required fullWidth id='correo-electronico' label='Correo Electrónico' variant='outlined' onChange={(e) => setCampos({ ...campos, correo: e.target.value })} />
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<TextField required fullWidth select id='pais' label='País' variant='outlined' value={campos.pais} onChange={(e) => setCampos({ ...campos, pais: e.target.value })}>
									<MenuItem value='Brasil'>Brasil</MenuItem>
									<MenuItem value='Colombia'>Colombia</MenuItem>
									<MenuItem value='Ecuador'>Ecuador</MenuItem>
									<MenuItem value='España'>España</MenuItem>
									<MenuItem value='Guatemala'>Guatemala</MenuItem>
									<MenuItem value='México'>México</MenuItem>
									<MenuItem value='Perú'>Perú</MenuItem>
								</TextField>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<TextField required fullWidth select id='rol' label='Rol' variant='outlined' onChange={(e) => setCampos({ ...campos, rol: e.target.value })}>
									<MenuItem value='Super Administrador'>Super Administrador</MenuItem>
									<MenuItem value='Administrador'>Administrador</MenuItem>
									<MenuItem value='Consultor'>Consultor</MenuItem>
								</TextField>
							</Grid>
							<Grid item lg={6} md={6} sm={12} xs={12}>
								<TextField type='tel' inputProps={{ pattern: '[0-9]{10}', maxLength: 10 }} fullWidth id='telefono' label='Teléfono (opcional)' placeholder='0000000000' variant='outlined' onChange={(e) => setCampos({ ...campos, telefono: e.target.value })} />
							</Grid>
							<Grid item lg={10} md={10} sm={10} xs={12}>
								<FormControlLabel value={campos.listaConsultores} onChange={(e) => setCampos({ ...campos, listaConsultores: !campos.listaConsultores })} control={<Switch color='primary' />} label='Mostrar en lista de consultores' labelPlacement='start' />
							</Grid>
							<Grid item lg={2} md={2} sm={2} xs={12}>
								<div className='full center'>
									<Button type='submit' variant='info' className='btn'>
										Registrar
									</Button>
								</div>
							</Grid>
						</Grid>
					</form>
				</section>
			</div>
		</>
	);
}
