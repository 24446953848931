import { useState, useEffect, createRef } from 'react';
import { db } from '../../../../config/firebase';
import { ref, onChildChanged } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { useScreenshot, createFileName } from 'use-react-screenshot';
import Grid from '@mui/system/Unstable_Grid';
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ResponsiveContainer, LabelList, ComposedChart } from 'recharts';
import groupBy from 'lodash.groupby';
import SectionTitle from '../../../../components/SeccionTitle';
import { getCollections } from '../../../../assets/js/functions';
import '../styles.scss';

export default function ConsultoresAdministradores() {
	const navigate = useNavigate();

	// satisfaccion general
	const [satisfaccionGeneral, setSatisfaccionGeneral] = useState([]);
	const reference = createRef(null);
	const [image, takeScreenshot] = useScreenshot();

	const download = (image, { name = 'satisfaccion-consultores', extension = 'png' } = {}) => {
		const a = document.createElement('a');
		a.href = image;
		a.download = createFileName(extension, name);
		a.click();
	};

	const getImage = () => {
		takeScreenshot(reference.current).then(download);
	};

	const COLORS = ['#5499C7 ', '#EC7063', '#C39BD3', '#48C9B0', '#CD6155', '#F1948A', '#EB984E', '#FF8042', '#00C49F', '#F9E79F'];

	const get = async () => {
		let array = [];
		let json = {};
		const resultados = await getCollections('satisfaccionGeneral');
		const resultadosG = await getCollections('satisfaccionGeneralG');
		const usuarios = await getCollections('usuarios');

		Object.values(resultados).map((val) => {
			Object.values(usuarios).map((valUsuarios) => {
				if (valUsuarios.activo && val.preguntaCuatro === valUsuarios.nombre) {
					array.push(val);
				}
			});
		});
		Object.values(resultadosG).map((val) => {
			Object.values(usuarios).map((valUsuarios) => {
				if (valUsuarios.activo && val.preguntaCuatro === valUsuarios.nombre) {
					array.push(val);
				}
			});
		});
		console.log(array);
		const orderData = groupBy(array, 'preguntaCuatro');
		const orderDataP = groupBy(resultados, 'preguntaCuatro');
		const orderDataG = groupBy(resultadosG, 'preguntaCuatro');
		// setDatos(orderData);<
		// getNps(orderData);
		json = {
			ganadas: orderDataG,
			perdidas: orderDataP,
		};
		satisfaccion(orderData, json);
		// satisfaccionConsultor(resultados);
	};

	const satisfaccion = async (json, a) => {
		const array = [];
		Object.keys(json).map((key) => {
			const values = json[key];
			let jsonResult = {};
			let suma = 0;
			let promedio = 0;
			if (values) {
				const valuess = Object.keys(values);
				Object.values(valuess).map((val) => {
					const data = json[key][val];
					if (key === data.preguntaCuatro) {
						suma =
							suma +
							parseInt(data.preguntaSeis) +
							parseInt(data.preguntaSiete) +
							parseInt(data.preguntaOcho) +
							parseInt(data.preguntaNueve) +
							parseInt(data.preguntaDiez) +
							parseInt(data.preguntaOnce) +
							parseInt(data.preguntaDoce) +
							parseInt(data.preguntaTrece) +
							parseInt(data.preguntaCatorce);
						promedio = suma / json[key].length / 9;
						jsonResult = {
							name: data.preguntaCuatro,
							promedio: parseFloat(promedio.toFixed(2)),
							encuestas: json[key].length,
							ganadas: a.ganadas[key] ? a.ganadas[key].length : 0,
							perdidas: a.perdidas[key] ? a.perdidas[key].length : 0,
							id: data.id,
						};
					} else {
						suma = 0;
						promedio = 0;
					}
				});
			}
			array.push(jsonResult);
		});
		console.log(array);
		setSatisfaccionGeneral(array);
	};

	useEffect(() => {
		get();
		const dbRef = ref(db, 'satisfaccionOperaciones/');
		onChildChanged(dbRef, (data) => {
			get();
		});
	}, []);

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className='custom-tooltip'>
					<p className='label'>{`${label} : ${payload[0].value}`}</p>
					<p className='label'>{`Encuestas Total : ${payload[0].payload.encuestas}`}</p>
					<p className='label'>{`Ganadas : ${payload[0].payload.ganadas}`}</p>
					<p className='label'>{`perdidas : ${payload[0].payload.perdidas}`}</p>
				</div>
			);
		}
		return null;
	};

	return (
		<>
			<div className='content-area'>
				<SectionTitle title='Satisfacción por consultor' />
				<section id='reporteConsultor'>
					<Grid direction='row' alignItems='center' justifyContent='center' spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div ref={reference} style={{ padding: '15px' }}>
								<ResponsiveContainer width='100%' height={900}>
									<ComposedChart
										layout='vertical'
										data={satisfaccionGeneral}
										margin={{
											left: 20,
										}}
									>
										<CartesianGrid stroke='#f5f5f5' />
										<XAxis type='number' domain={[0, 5]} ticks={[1, 2, 3, 4, 5]} />
										<YAxis dataKey='name' type='category' />
										<Tooltip content={<CustomTooltip />} />
										<Bar dataKey='promedio' position='centerTop'>
											{satisfaccionGeneral.map((entry, index) => (
												<>
													<Cell style={{ cursor: 'pointer' }} onClick={(e) => navigate(`/admin/comercial/consultor/${entry.name}`)} key={`cell-${index}`} id={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
												</>
											))}
											<LabelList fill='#000' dataKey='promedio' position='centerTop' />
										</Bar>
									</ComposedChart>
								</ResponsiveContainer>
							</div>
						</Grid>
					</Grid>
					<Grid direction='row' alignItems='center' justifyContent='center' spacing={2}>
						<Grid item lg={2} className='center'>
							<div className='btn' onClick={getImage}>
								Descargar gráfica
							</div>
						</Grid>
					</Grid>
				</section>
			</div>
		</>
	);
}
