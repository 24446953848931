import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../../config/firebase';
import { ref, onChildChanged } from 'firebase/database';
import Grid from '@mui/system/Unstable_Grid';
import Swal from 'sweetalert2';
import { DataGrid } from '@mui/x-data-grid';
import { Tooltip, Button } from '@mui/material';
import { FaLink, FaTrash } from 'react-icons/fa6';
import { esES } from '@mui/x-data-grid/locales';
import orderBy from 'lodash.orderby';
import { getCollections, deleteData, stringToArray } from '../../../../assets/js/functions';
import SectionTitle from '../../../../components/SeccionTitle';
import InputSearch from '../../../../components/InputSearch';
import './styles.scss';

export default function EncuestaConsultorLista() {
	const navigate = useNavigate();
	const [rowsTable, setRowsTable] = useState([]);

	useEffect(() => {
		getEncuestas();
		const dbRef = ref(db, 'encuestaDeSatisfaccionComercial/');
		onChildChanged(dbRef, (data) => {
			getEncuestas();
		});
	}, []);

	const getEncuestas = async () => {
		let array = [];
		let fecha = [];

		const data = await getCollections('encuestaDeSatisfaccionComercial');
		if (sessionStorage.getItem('8b6d1c1e') === '051c0d485398') {
			Object.values(data).map((val) => {
				if (val.idUsuario === sessionStorage.getItem('3c393f98')) {
					fecha = stringToArray(val.fechaRegistroConsultor, ' ');
					array.push({ ...val, fecha: `${fecha[0]}` });
				}
			});
		} else {
			Object.values(data).map((val) => {
				fecha = stringToArray(val.fechaRegistroConsultor, ' ');
				array.push({ ...val, fecha: `${fecha[0]}` });
			});
		}
		const ordernados = orderBy(array, ['preguntaUno'], ['asc']);
		setRowsTable(ordernados);
	};

	const busquedaFunction = (busqueda) => {
		setRowsTable(busqueda);
	};

	const eliminar = (id) => {
		Swal.fire({
			title: '¡Espera!',
			html: `¿Deseas eliminar la encuesta?`,
			icon: 'warning',
			confirmButtonText: 'Confirmar',
			confirmButtonColor: '#c1212a',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
		}).then((result) => {
			if (result.isConfirmed) {
				deleteData('encuestaDeSatisfaccionComercial', id)
					.then(() => {
						Swal.fire({
							icon: 'success',
							title: '!Bien hecho¡',
							html: `La encuesta se elimino correctamente`,
							confirmButtonColor: '#e10613',
						});
						getEncuestas();
					})
					.catch((e) => {
						const errorCode = e.code;
						const errorMessage = e.message;
						console.log(errorCode);
						console.log(errorMessage);
						Swal.fire({
							title: 'Oooops!',
							text: 'Contacta un administrador',
							icon: 'error',
							confirmButtonText: 'Cerrar',
							confirmButtonColor: '#c1212a',
						});
					});
			}
		});
	};

	const copiar = (id) => {
		navigator.clipboard.writeText(`https://bi-spira.web.app/encuesta-de-satisfaccion-comercial/${id}`);
		Swal.fire({
			icon: 'success',
			title: 'Se copio el link',
			html: `https://bi-spira.web.app/encuesta-de-satisfaccion-comercial/${id}`,
			confirmButtonColor: '#e10613',
		});
	};

	const columns = [
		{ field: 'preguntaUno', headerName: 'EMPRESA', flex: 2 },
		{ field: 'preguntaCuatro', headerName: 'PROYECTO', flex: 2 },
		{ field: 'fecha', headerName: 'Fecha de registro', flex: 2 },

		{
			field: 'status',
			headerName: 'ESTADO',
			width: 200,
			sortable: false,
			renderCell: (params) => {
				return (
					<>
						{params.row.status === 'Sin abrir' && <div className='badge rojo'>Sin abrir</div>}
						{params.row.status === 'Abierto' && <div className='badge amarillo'>Abierto</div>}
						{params.row.status === 'Terminado' && <div className='badge verde'>Terminado</div>}
					</>
				);
			},
		},
		{
			field: 'action',
			headerName: 'OPCIONES',
			width: 200,
			sortable: false,
			renderCell: (params) => {
				return (
					<>
						<Tooltip title='Copiar link' placement='top'>
							<Button className='cursor' onClick={() => copiar(params.id, params.row.rol)}>
								<FaLink size={20} />
							</Button>
						</Tooltip>
						<Tooltip title='Eliminar' placement='top'>
							<Button className='cursor' onClick={() => eliminar(params.id, params.row.rol)}>
								<FaTrash size={20} />
							</Button>
						</Tooltip>
					</>
				);
			},
		},
	];

	return (
		<div className='content-area'>
			<SectionTitle title='Encuestas de satisfacción comercial' />
			<section id='encuestaConsultorLista'>
				<Grid container direction='row' alignItems='stretch' spacing={2}>
					{sessionStorage.getItem('8b6d1c1e') === '0242ac120002' && (
						<Grid item lg={10} md={10} sm={10} xs={12}>
							<InputSearch busquedaProps={busquedaFunction} collection='encuestaDeSatisfaccionComercial' />
						</Grid>
					)}
					<Grid item lg={2} md={2} sm={2} xs={12}>
						<div className='btn' onClick={() => navigate('/admin/encuestas/encuesta-de-satisfaccion-comercial')}>
							Crear
						</div>
					</Grid>
					<Grid xs={12} sm={12} md={12} lg={12}>
						<div style={{ height: `${rowsTable.length > 0 ? 'auto' : '600px'}`, width: '100%' }}>
							<DataGrid
								rows={rowsTable}
								columns={columns}
								pageSize={10}
								initialState={{
									pagination: { paginationModel: { pageSize: 10 } },
								}}
								pageSizeOptions={[10, 20, 50, 100]}
								rowsPerPageOptions={[10]}
								localeText={esES.components.MuiDataGrid.defaultProps.localeText}
							/>
						</div>
					</Grid>
				</Grid>
			</section>
		</div>
	);
}
