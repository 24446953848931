import React, { useState, useEffect } from 'react';
// material-ui
import { Container, Grid, TextField, MenuItem, InputLabel, FormControl, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import Swal from 'sweetalert2';
// project imports
import { setData, getDate, top, getCollections } from '../../../assets/js/functions';
import uniqid from 'uniqid';
import orderBy from 'lodash.orderby';
import Logo from '../../../assets/images/login/logo.png';
import './styles.scss';

export default function SatisfaccionGeneral() {
	const [datos, setDatos] = useState({});
	const [seccionVisible, setSeccionVisible] = useState(1);
	const [otro, setOtro] = useState(false);
	const [otroPais, setOtroPais] = useState(false);
	const [consultores, setConsultores] = useState([]);

	useEffect(() => {
		top();
		getConsultores();
		// removeElementsByClass('theme-toggle-btn');
	}, []);

	const getConsultores = async () => {
		let array = [];
		const data = await getCollections('usuarios');
		Object.values(data).map((val) => {
			if (val.listaConsultores && val.activo) {
				array.push(val);
			}
		});
		const ordernados = orderBy(array, ['nombre'], ['asc']);
		setConsultores(ordernados);
	};

	const registrar = () => {
		const id = uniqid();
		const json = {
			...datos,
			id,
			fechaRegistro: getDate(),
		};
		if (Object.keys(json).length < 17) {
			Swal.fire({
				icon: 'warning',
				title: 'Cuidado',
				text: 'Tienes preguntas sin contestar',
				confirmButtonColor: '#e10613',
			});
		} else {
			setData('satisfaccionGeneral', id, json).then(() => {
				Swal.fire({
					icon: 'success',
					title: 'Bien Hecho',
					text: 'Datos guardados exitosamente...',
					confirmButtonColor: '#e10613',
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.reload();
					}
				});
			});
		}
	};

	const setPaisCliente = (pais) => {
		if (pais === 'Otro') {
			setOtroPais(true);
		} else {
			setOtroPais(false);
			setDatos({ ...datos, preguntaDos: pais });
		}
	};

	const setConsultor = (consultor) => {
		if (consultor === 'Otro') {
			setOtro(true);
		} else {
			setOtro(false);
			setDatos({ ...datos, preguntaCuatro: consultor });
		}
	};

	const cambiarSeccion = () => {
		top();
		setSeccionVisible(seccionVisible + 1);
	};

	let backgroundStyles = '';
	backgroundStyles += `#root::after {background-color: #fff }`;

	return (
		<>
			<style>{backgroundStyles}</style>
			<div className='header-free'>
				<img src={Logo} alt='logo' />
			</div>
			<Container maxWidth='lg' style={{ marginBottom: '40px' }}>
				<Grid container justifyContent='center' alignItems='center' spacing={4}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div className='titulo-encuesta'>Satisfacción General</div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<>
							<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
								Por favor responda las siguientes preguntas según su apreciación de nuestro servicio, siendo 1 la calificación más baja.
							</InputLabel>
						</>
					</Grid>
					{/* seccionVisible 1 */}
					{seccionVisible === 1 && (
						<>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										1.Por favor indique la empresa a la que pertenece
									</InputLabel>
									<TextField label='' required fullWidth variant='outlined' onChange={(e) => setDatos({ ...datos, preguntaUno: e.target.value })} value={datos.preguntaUno} />
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										2.País de Operación del Cliente
									</InputLabel>
									<TextField label='' required fullWidth select variant='outlined' value={datos.preguntaDos} onChange={(e) => setPaisCliente(e.target.value)}>
										<MenuItem value='Argentina'>Argentina</MenuItem>
										<MenuItem value='Bolivia'>Bolivia</MenuItem>
										<MenuItem value='Brasil'>Brasil</MenuItem>
										<MenuItem value='Chile'>Chile</MenuItem>
										<MenuItem value='Colombia'>Colombia</MenuItem>
										<MenuItem value='Costa Rica'>Costa Rica</MenuItem>
										<MenuItem value='Cuba'>Cuba</MenuItem>
										<MenuItem value='Ecuador'>Ecuador</MenuItem>
										<MenuItem value='El Salvador'>El Salvador</MenuItem>
										<MenuItem value='España'>España</MenuItem>
										<MenuItem value='Estados Unidos'>Estados Unidos</MenuItem>
										<MenuItem value='Granada'>Granada</MenuItem>
										<MenuItem value='Guatemala'>Guatemala</MenuItem>
										<MenuItem value='Guayana'>Guayana</MenuItem>
										<MenuItem value='Guayana Francesa'>Guayana Francesa</MenuItem>
										<MenuItem value='Haití'>Haití</MenuItem>
										<MenuItem value='Honduras'>Honduras</MenuItem>
										<MenuItem value='Jamaica'>Jamaica</MenuItem>
										<MenuItem value='México'>México</MenuItem>
										<MenuItem value='Nicaragua'>Nicaragua</MenuItem>
										<MenuItem value='Panamá'>Panamá</MenuItem>
										<MenuItem value='Paraguay'>Paraguay</MenuItem>
										<MenuItem value='Perú'>Perú</MenuItem>
										<MenuItem value='Puerto Rico'>Puerto Rico</MenuItem>
										<MenuItem value='República Dominicana'>República Dominicana</MenuItem>
										<MenuItem value='Surinam'>Surinam</MenuItem>
										<MenuItem value='Uruguay'>Uruguay</MenuItem>
										<MenuItem value='Venezuela'>Venezuela</MenuItem>
										<MenuItem value='Otro'>Otro</MenuItem>
									</TextField>
									&nbsp;
									{otroPais && <TextField label='' required fullWidth helperText='Ingresa el nombre del país' variant='outlined' onChange={(e) => setDatos({ ...datos, preguntaDos: e.target.value, otroPais: true })} />}
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										3.País de <span className='bold'>CONSULTOR COMERCIAL</span>
									</InputLabel>
									<TextField label='' required fullWidth select variant='outlined' value={datos.preguntaTres} onChange={(e) => setDatos({ ...datos, preguntaTres: e.target.value })}>
										<MenuItem value='Colombia'>Colombia</MenuItem>
										<MenuItem value='Guatemala'>Guatemala</MenuItem>
										<MenuItem value='México'>México</MenuItem>
										<MenuItem value='Perú'>Perú</MenuItem>
									</TextField>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										4.Por favor seleccione al <span className='bold'>CONSULTOR COMERCIAL</span>. Si su respuesta es Otro por favor indique el nombre.
									</InputLabel>
									<TextField label='' required fullWidth select variant='outlined' value={datos.preguntaCuatro} onChange={(e) => setConsultor(e.target.value)}>
										{Object.values(consultores).map((val, index) => (
											<MenuItem key={index} value={val.nombre}>
												{val.nombre}
											</MenuItem>
										))}
										<MenuItem value='Otro'>Otro</MenuItem>
									</TextField>
									&nbsp;
									{otro && <TextField label='' required fullWidth helperText='Ingresa el nombre del consultor' variant='outlined' onChange={(e) => setDatos({ ...datos, preguntaCuatro: e.target.value, otroConsultor: true })} />}
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										5.Por favor indique el proyecto o propuesta a evaluar
									</InputLabel>
									<TextField label='' required fullWidth variant='outlined' onChange={(e) => setDatos({ ...datos, preguntaCinco: e.target.value })} value={datos.preguntaCinco} />
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										6.¿El asesor comercial fue cordial durante todo el proceso comercial?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' value={datos.preguntaSeis} onChange={(e) => setDatos({ ...datos, preguntaSeis: e.target.value })}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
						</>
					)}
					{/* seccionVisible 2 */}
					{seccionVisible === 2 && (
						<>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										7.¿El asesor comercial fue puntual en las citas acordadas?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' value={datos.preguntaSiete} onChange={(e) => setDatos({ ...datos, preguntaSiete: e.target.value })}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										8.¿El asesor comercial realizó la presentación de Spira y le permitió entender con claridad los servicios que prestamos, nuestra experiencia y casos de éxito?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' value={datos.preguntaOcho} onChange={(e) => setDatos({ ...datos, preguntaOcho: e.target.value })}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										9.¿El Asesor comercial demostró haber entendido las necesidades de su negocio /operación cuando presentó la propuesta?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' value={datos.preguntaNueve} onChange={(e) => setDatos({ ...datos, preguntaNueve: e.target.value })}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										10.¿El asesor comercial le entregó la propuesta comercial en el tiempo acordado?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' value={datos.preguntaDiez} onChange={(e) => setDatos({ ...datos, preguntaDiez: e.target.value })}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										11.¿Cuándo el asesor comercial presentó la oferta personalmente, le aclaró las dudas o inquietudes presentadas por su organización?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' value={datos.preguntaOnce} onChange={(e) => setDatos({ ...datos, preguntaOnce: e.target.value })}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
						</>
					)}
					{/* seccionVisible 3 */}
					{seccionVisible === 3 && (
						<>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										12.¿El asesor comercial evidenció los beneficios que obtendrá su organización en el aumento del desempeño y/o el cumplimiento de sus objetivos?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' value={datos.preguntaDoce} onChange={(e) => setDatos({ ...datos, preguntaDoce: e.target.value })}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										13.¿La propuesta comercial fue clara y respondió a sus necesidades?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' value={datos.preguntaTrece} onChange={(e) => setDatos({ ...datos, preguntaTrece: e.target.value })}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										14.¿Después de la presentación de la propuesta, el asesor comercial mantuvo un permanente contacto e hizo seguimiento de esta?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' value={datos.preguntaCatorce} onChange={(e) => setDatos({ ...datos, preguntaCatorce: e.target.value })}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<>
									<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
										15.En una escala de 0 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?
									</InputLabel>
									<FormControl>
										<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' value={datos.preguntaQuince} onChange={(e) => setDatos({ ...datos, preguntaQuince: e.target.value })}>
											<FormControlLabel
												value='1'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='1'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='2'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='2'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='3'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='3'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='4'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',

															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='4'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='5'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='5'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='6'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='6'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='7'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='7'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='8'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='8'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='9'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='9'
												labelPlacement='bottom'
											/>
											<FormControlLabel
												value='10'
												control={
													<Radio
														size='large'
														sx={{
															color: '#5a5a5a',
															'&.Mui-checked': {
																color: '#e10613',
															},
														}}
													/>
												}
												label='10'
												labelPlacement='bottom'
											/>
										</RadioGroup>
									</FormControl>
								</>
							</Grid>
						</>
					)}

					{/* Botones */}
					<Grid item xs={12} sm={12} md={12} lg={12}>
						{seccionVisible > 1 && (
							<Button className='blanco' variant='contained' style={{ background: '#e10613' }} onClick={() => setSeccionVisible(seccionVisible - 1)}>
								Regresar
							</Button>
						)}
						&nbsp;
						<Button className='blanco' variant='contained' style={{ background: '#e10613', color: '#fff' }} onClick={() => (seccionVisible !== 3 ? cambiarSeccion() : registrar())}>
							{seccionVisible !== 3 ? 'Siguiente' : 'Enviar'}
						</Button>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}
